import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';
import classNames from 'classnames';
import Sidebar from './Sidebar';
import Modal from './Modal';
import logo from './mainlogo.png';
import './Sidebar.css';
import './App.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import DOMPurify from 'dompurify';

// deploy nov 16 2024 1.0

function PostContent({ content, children }) {
  console.log("Content before rendering:", content);
  return (
    <div className="post-content">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
      {children}
    </div>
  );
}


const client = createClient({
  space: 'f4pjv0zb14pf', // Replace with your space ID
  accessToken: 'bTFU1rWhun24KuAzDghMuuiWuCdSQDT2rSBS7qTiq44' // Replace with your access token
});


function Slideshow({ slides }) {
  const [slideIndex, setSlideIndex] = useState(0);

  const nextSlide = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setSlideIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  let touchStart = null;

  const handleTouchStart = (e) => {
    touchStart = e.targetTouches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (!touchStart) {
      return;
    }
    const currentTouch = e.targetTouches[0].clientX;
    const diff = touchStart - currentTouch;

    if (diff > 50) {
      nextSlide();
      touchStart = null;  // Reset touchStart to prevent multiple slides on long swipes
    } else if (diff < -50) {
      prevSlide();
      touchStart = null;  // Reset touchStart to prevent multiple slides on long swipes
    }
  };

  return (
    <div className="slideshow-container" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      {slides.map((slide, index) => (
        <div key={index} className="slide" style={{ display: index === slideIndex ? 'block' : 'none' }}>
          <img src={slide.fields.file.url} alt={slide.fields.title} className="individual-post-image-slideshow" />
        </div>
      ))}
      <button className="prev" onClick={prevSlide}>&#10094;</button>
      <button className="next" onClick={nextSlide}>&#10095;</button>
    </div>
  );
}


const truncateText = (text, maxLength) => {
  if (!text) return '';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

function App() {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Ideas & Principles');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);
  const [titleLimit, setTitleLimit] = useState(80); // Default character limit for title
  const [summaryLimit, setSummaryLimit] = useState(180); // Default character limit for summary
// example
  useEffect(() => {
    client.getEntries({ content_type: 'blogPost' })
      .then((response) => {
        console.log(response.items);
        setPosts(response.items);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth <= 800);
      
      if (screenWidth <= 400) {
        setTitleLimit(20);
        setSummaryLimit(80);
      } else if (screenWidth <= 600) {
        setTitleLimit(25);
        setSummaryLimit(80);
      } else if (screenWidth > 600 && screenWidth <= 700) {
        setTitleLimit(40);
        setSummaryLimit(100);
      } else if (screenWidth > 700 && screenWidth <= 800) {
        setTitleLimit(50);
        setSummaryLimit(120);
      } else if (screenWidth > 800 && screenWidth <= 900) {
        setTitleLimit(30);
        setSummaryLimit(65);
      } else if (screenWidth > 900 && screenWidth <= 1000) {
        setTitleLimit(45);
        setSummaryLimit(90);
      } else if (screenWidth > 1000 && screenWidth <= 1100) {
        setTitleLimit(55);
        setSummaryLimit(120);
      } else if (screenWidth > 1100 && screenWidth <= 1200) {
        setTitleLimit(65);
        setSummaryLimit(130);
      } else if (screenWidth > 1400 && screenWidth <= 1600) {
        setTitleLimit(35);
        setSummaryLimit(80);
      } else {
        setTitleLimit(50);
        setSummaryLimit(100);
      }

      // Automatically close the sidebar if the screen is resized to a larger width
      if (screenWidth > 800 && isSidebarOpen) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the function initially to set the limits based on the initial screen size

    return () => window.removeEventListener('resize', handleResize);
  }, [isSidebarOpen]);

  const handlePostClick = post => {
    // Prepare post body with images before setting the selected post
    const processedBody = processPostBody(post.fields.body, post.fields.media);
    const updatedPost = { ...post, fields: { ...post.fields, body: processedBody }, slideshowMedia: post.fields.slideshowMedia };
    setSelectedPost(updatedPost);
  };

  const processPostBody = (body, media) => {
    const placeholderRegex = /\{([^}]+)\}/g;
    const processedContent = body.replace(placeholderRegex, (match, imageName) => {
      const image = media.find(({ fields }) => fields.title === imageName);
      if (image) {
        // Use Markdown syntax for images
        return `![${imageName}](${image.fields.file.url})`;
      }
      return match; // Return the original placeholder if no image is found
    });
    return processedContent; // Now the content is Markdown, not HTML
  };

  
  const handleClosePost = () => setSelectedPost(null);
  const handleCloseModal = () => setSelectedPost(null);
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedPost(null);
  }
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const closeSidebar = () => setIsSidebarOpen(false);

  const filteredPosts = selectedCategory === 'All'
    ? posts
    : posts.filter(post => post.fields.category === selectedCategory);

  return (
    <div className={classNames('app', { 'sidebar-open': isSidebarOpen })}>
      {(isSmallScreen && !isSidebarOpen) && (
        <div className="header">
          <div className="logo-container">
            <img src={logo} className="ux-logo" alt="UX Logo" />
          </div>
          <div className="icon-container">
            <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
              <div className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
      )}
      <Sidebar
        onCategoryChange={handleCategoryChange}
        selectedCategory={selectedCategory}
        isOpen={isSidebarOpen}
        closeSidebar={closeSidebar}
        isSmallScreen={isSmallScreen}
      />
    <div className={`content ${selectedPost ? 'allow-scroll' : 'no-scroll'}`} onClick={closeSidebar}>
        {selectedPost ? (
          <div className="individual-post">
            <h1 className="individual-post-title">{selectedPost.fields.title}</h1>
            <PostContent content={selectedPost.fields.body}>
              {selectedPost.slideshowMedia && <Slideshow slides={selectedPost.slideshowMedia} />}
            </PostContent>
          </div>
        ) : (
                      // Render List of Posts
          <>
            <h1 className="filter-title">{selectedCategory}</h1>
            <div className={`blog-posts ${selectedCategory.toLowerCase().replace(/\s+/g, '-').replace(/&/g, 'and')}`}>
              {filteredPosts.length === 0 ? (
                <p>No posts available in this category.</p>
              ) : (
                filteredPosts.map(post => (
                  <div 
                    key={post.sys.id}
                    className={`blog-post blog-post-shared ${post.fields.category === 'Ideas & Principles' ? 'blog-post-ideas' : 'blog-post-stories'}`}
                    onClick={() => handlePostClick(post)}
                  >
                    <img src={post.fields.mainMedia.fields.file.url} alt={post.fields.title} className={`blog-post-image ${post.fields.category === 'Ideas & Principles' ? 'blog-post-ideas-image' : ''}`}/>
                    <div className={`blog-post-text ${post.fields.category === 'Ideas & Principles' ? 'text-center' : 'text-left'}`}>
                      <p className={`blog-title ${post.fields.category === 'Ideas & Principles' ? 'blog-post-ideas-title' : ''}`}>
                        {truncateText(post.fields.title, titleLimit)}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function TestMarkdown() {
  const simpleMarkdown = `# Heading\n\n**Bold text**\n\n- List item 1\n- List item 2`;
  return <ReactMarkdown remarkPlugins={[remarkGfm]}>{simpleMarkdown}</ReactMarkdown>;
}

export default App;
